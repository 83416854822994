.member-card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.member-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.member-image {
  width: 100%; /* Image will take the full width of its container */
  max-height: 200px; /* Set a maximum height for the image */
  object-fit: cover; /* Ensures the image covers the space without distortion */
  transition: transform 0.3s ease-in-out;
}

.member-card:hover .member-image {
  transform: scale(1.1);
}

.member-details {
  padding: 10px;
}

.expand-btn {
  margin-top: 10px;
  display: block;
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.expand-btn:hover {
  background-color: #0056b3;
}

.expanded-details {
  margin-top: 10px;
}
