.webinar-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #e2e8f0;
    /* Light blue background */
}

.content-container {
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 2.5rem;
    color: #1a202c;
    text-align: center;
    margin-bottom: 30px;
}

.webinarcard-container,
.youtube-section {
    flex: 1;
    display: flex;
    flex-direction: column;
justify-content: flex-start;
    gap: 20px;
    margin: 10px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #cbd5e0;
}

.webinar-card h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.webinar-card p,
.webinar-card ul {
    font-size: 1rem;
    color: #4a5568;
}

.webinar-card ul {
    list-style: none;
    padding-left: 0;
}

.navigation button {
    background-color: #48bb78;
    color: white;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.navigation button:hover {
    background-color: #38a169;
}

.youtube-section h2 {
    font-size: 1.8rem;
    color: #2d3748;
    margin-bottom: 20px;
    text-align: center;
}

.video-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.video-grid iframe {
    width: 100%;
    height: 315px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
    .video-grid {
        grid-template-columns: 1fr;
    }
}