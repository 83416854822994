/* General styling for the navigation bar */

.navbar-brand {
  font-weight: bold;
  font-size: 24px;
  color: #333;
}

.nav-link {
  font-size: 16px;
  color: #555;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #007bff; /* Bootstrap primary color */
}

/* Styling for dropdown menu */
.nav-dropdown {
  transition: background-color 0.3s ease;
}

.nav-dropdown:hover,
.nav-dropdown:focus {
  background-color: #f8f9fa; /* Slightly darker background on hover/focus */
}

/* Custom styling for mobile view */
@media (max-width: 992px) {
  .navbar {
    padding: 10px 20px;
  }

  .nav-link {
    padding: 10px 15px;
  }
}

/* Navigation.css */

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* To ensure the navbar appears above other content */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow for a bit of depth */
}

body {
  padding-top: 80px;
  /* This ensures that the body content doesn't hide behind the fixed navbar. Adjust the value based on the height of your navbar. */
}