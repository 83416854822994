.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f4f4f4;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  width: 100%;
  color: white;
  text-align: center;
}

table {
  border-collapse: collapse;
  margin: 20px 0;
  width: 80%;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

table th,
table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
}

table th {
  background-color: #f2f2f2;
  color: #333;
}

table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

table tbody tr:hover {
  background-color: #f0dbdb;
}
.id-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.id-card img {
  border-radius: 0;
  height: 100%;
  object-fit: cover;
}

.id-card .card-body {
  padding: 10px;
}

.card-hover-effect {
  transition: transform 0.3s ease-in-out;
}

.card-hover-effect:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
nav {
  background-color: #333;
  padding: 1rem 0;
}

nav ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

nav li {
  margin: 0 1rem;
}

nav a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s;
}

nav a:hover {
  background-color: #555;
}
.nav-links {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding: 10px;
  background-color: #f4f4f4;
}

.nav-links li {
  margin: 0 15px;
}

.active-link {
  font-weight: bold;
  color: blue;
}
