body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  width: 100%;
  color: white;
  text-align: center;
}

table {
  border-collapse: collapse;
  margin: 20px 0;
  width: 80%;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

table th,
table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
}

table th {
  background-color: #f2f2f2;
  color: #333;
}

table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

table tbody tr:hover {
  background-color: #f0dbdb;
}
