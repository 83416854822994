/* Reset and Base Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f4f4f4;
}

/* Utility Classes */
.text-center {
    text-align: center;
}

/* Button Styles */
button {
    background-color: #007bff;
    color: #fff;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

button:hover {
    background-color: #0056b3;
}

/* Container Styles */
.container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}

/* Header and Typography Styles */
.bg-primary {
    background-color: #007bff;
}

.text-white {
    color: #fff;
}

h3 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
}

/* Grid System */
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 15px;
    padding-left: 15px;
}

/* Card Styles */
.card {
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
}

.card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.75rem 1.25rem;
    font-size: 1.25rem;
    font-weight: 500;
}

.card-body {
    padding: 1.25rem;
}

.card-text {
    margin-top: 0;
    margin-bottom: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .col-md-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* Accessibility Improvements */
a,
button {
    outline: none;
    box-shadow: none;
}

a:focus,
button:focus {
    outline: 2px dashed #007bff;
    outline-offset: 2px;
}

.language-switcher-btn {
    float: right;
    /* Aligns the button to the left */
    font-size: 0.8rem;
    /* Smaller font size for the button */
    padding: 5px 10px;
    /* Smaller padding to reduce button size */
    margin-right: 10px;
    /* Reduced space to the next item */
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
/* Section one */
/* Base Styles */
.bg-white {
    background-color: #ffffff;
}

.text-gray-800 {
    color: #333333;
    /* Dark gray for contrast */
}

.container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 20px;
}

/* Typography */
.text-3xl {
    font-size: 2rem;
    /* Classical and readable size */
    line-height: 2.5rem;
    font-family: 'Georgia', serif;
    /* Adds a classical touch */
}

.font-bold {
    font-weight: 700;
    /* Bold for emphasis */
}

.text-center {
    text-align: center;
}

.my-8 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

/* Image and Video Container */
.bg-blue-200 {
    background-color: #e6f1ff;
    /* Light and soft blue */
    padding: 1rem;
    border-radius: 10px;
    /* Slightly larger radius for elegance */
}

.rounded-lg {
    border-radius: 10px;
}

.overflow-hidden {
    overflow: hidden;
}

.w-full {
    width: 100%;
}

.object-cover {
    object-fit: cover;
}

.h-96 {
    height: 400px;
    /* Adjusted for better proportion */
}

/* Buttons */
.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    /* Space between buttons */
}

button {
    background-color: #0056b3;
    /* Rich, classical blue */
    color: #ffffff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #003f7f;
    /* Slightly darker on hover */
}

/* Video Container */
iframe {
    width: 100%;
    height: 500px;
    border-radius: 10px;
    /* Consistent with image style */
    border: none;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .flex {
        flex-direction: column;
    }

    .h-96 {
        height: auto;
        /* Adjust height for smaller screens */
    }

    .text-3xl {
        font-size: 1.5rem;
        /* Smaller font for smaller screens */
    }
}


/* Second Section Styles */
.container {
    max-width: 1200px;
    /* Adjust width as needed */
    margin: 0 auto;
    padding: 16px;
}

/* ... other styles ... */

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* Adjust card size here */
    gap: 20px;
    /* Increased gap for more space between cards */
}

.card {
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

/* ... other styles ... */

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .grid-cols-1 {
        grid-template-columns: repeat(1, 1fr);
    }
}